<template>
  <div class="page-container">
    <div class="card-container">
      <div class="header">
        <img :src="logoIcon" width="98" alt="" class="logo" />
        <div class="title">{{ translateTitle("安全验证要求") }}</div>
        <div class="tip">
          {{ translateTitle("你需要完成以下验证方可继续") }}
        </div>
        <div class="step">
          <span>{{ Object.keys(tokenVerify).length }}</span
          >/{{ minVerifyNum }}
        </div>
      </div>
      <div class="verify-list">
        <div
          class="verify-item"
          v-for="item in needVerifyList"
          :key="item.type"
          @click="toVerify(item)"
        >
          <img :src="item.icon" width="36" alt="" />
          <span>{{ translateTitle(item.label) }}</span>
          <ArrowRightIcon v-if="!tokenVerify[item.type]" />
          <img :src="checkIcon" v-else width="18" alt="" />
        </div>
      </div>

      <el-button
        class="next-btn"
        type="primary"
        :disabled="Object.keys(tokenVerify).length < minVerifyNum"
        @click="onConfirmVerify"
        >{{ translateTitle("确认") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import logoIcon from "@/assets/img/users/logo.png";
import googleIcon from "@/assets/img/users/mobile.png";
import mobileIcon from "@/assets/img/users/mobile.png";
import emailIcon from "@/assets/img/users/email.png";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import checkIcon from "@/assets/img/users/check.png";

export default {
  components: {
    ArrowRightIcon,
  },
  data() {
    let tokenVerify = {};
    const query = this.$route.query;
    if (query.tokenVerify) {
      tokenVerify = JSON.parse(window.decodeURIComponent(query.tokenVerify));
    }
    return {
      logoIcon,
      checkIcon,
      formRules: {
        password: [
          {
            required: true,
            message: this.translateTitle("请输入密码"),
            trigger: "blur",
          },
        ],
      },
      formData: {
        password: "",
      },
      tokenVerify: tokenVerify,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    minVerifyNum() {
      const authInfo = this.$route.params.authInfo || {};
      return authInfo.min_verify_num || 1;
      // const authInfo = this.authInfo ? this.authInfo : this.userInfo.authInfo;
      // return authInfo.min_verify_num || 1;
    },
    allVerifyList() {
      return [
        {
          label: this.translateTitle("谷歌验证"),
          icon: googleIcon,
          type: "google",
        },
        {
          label: this.translateTitle("手机验证"),
          icon: mobileIcon,
          type: "phone",
        },
        {
          label: this.translateTitle("邮箱验证"),
          icon: emailIcon,
          type: "email",
        },
      ];
    },
    openList() {
      const authInfo = this.$route.params.authInfo || {};
      const list = this.allVerifyList.filter(item => {
        if (item.type === "google" && authInfo.open_google === 0) return false;
        if (item.type === "phone" && authInfo.open_mobile === 0) return false;
        if (item.type === "email" && authInfo.open_email === 0) return false;
        return true;
      });
      return list;
    },
    needVerifyList() {
      const keys = Object.keys(this.tokenVerify);
      if (keys.length > 0) {
        return this.openList.filter(item => keys.includes(item.type));
      }
      return this.openList.slice(0, this.minVerifyNum);
    },
  },
  created() {
    console.log("params", this.$route.params);
  },
  methods: {
    toVerify(data) {
      this.$router.push({
        name: "SafetyVerifyCode",
        query: {
          ...this.$route.query,
          from: data.type,
          tokenVerify: window.encodeURIComponent(
            JSON.stringify(this.tokenVerify)
          ),
          minVerifyNum: this.minVerifyNum,
        },
        params: {
          ...this.$route.params,
        },
      });
    },
    onConfirmVerify() {
      this.$router.push({
        name: this.$route.query.backRouteName || "LoginInputPassword",
        query: this.$route.query,
        params: this.$route.params,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/common.scss";
.account {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-top: 12px;
}

.next-btn {
  margin-top: 143px !important;
}
.other-btn {
  text-align: left !important;
}
.tip {
  margin-top: 8px;
  color: var(---c_t02, #5c5f69);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}
.step {
  color: #0d0e0e;

  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
  span {
    color: var(--7-c_m, #3348f6);
  }
}

.verify-item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(52, 106, 245, 0.06);
  width: 335px;
  height: 68px;
  margin-bottom: 12px;
  padding: 0 20px;
  cursor: pointer;
  span {
    margin-left: 10px;
    margin-right: auto;
    color: var(--7-c_t01, #0d0e0e);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
  }
}
.verify-list {
  margin-top: 34px;
}
</style>
